import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from "gatsby";

const HappyCustomers = () => (
  <StaticQuery
    query={graphql`
      query {
        fileName: file(relativePath: { eq: "gvd-happy-customers.png" }) {
          childImageSharp {
            gatsbyImageData(width: 370)
          }
        }
      }
    `}
    render={(data) => {
      return (
        <GatsbyImage
          className="border-radius mb-3"
          image={getImage(data.fileName.childImageSharp)}
          alt="A+ Window Tinting Happy Customers"
        />
      );
    }}
  />
);
export default HappyCustomers;
