import React, { useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import CardGeneral from "./card-general";
import { Check, CheckCircle } from "react-feather";
import TTPro from  "./sidebars/ttpro.svg"
import Button from "./atoms/Button";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { navigate } from "gatsby";
import { BadgeCheck, ListOrdered, Smile, Sparkles, Users2 } from "lucide-react";

const WhyUs = () => {
  const [claimingInProgress, setClaimingInProgress] = useState(false);

  const [offer, setOffer] = useLocalStorage("offers", {});

  const claimOffer = () => {
    setOffer({ offer: "$50 OFF Tesla Wall Charger Installation", expires: "" });
    setClaimingInProgress(true);
    setTimeout(() => {
      navigate("/contact/");
    }, 1000);
  };

  return (
    <StaticQuery
      query={graphql`
        query WhyUsListQuery {
          site {
            siteMetadata {
              whyUs
            }
          }
        }
      `}
      render={(data) => {
        return (
          <>
       
            <div className="rounded-xl mb-2 shadow bg-white ">
              <div class="px-8 pt-8 pb-6 border-b">
                <h4 class="text-gray-900 font-bold text-xl md:text-2xl font-display">Why US?</h4>
                <p class="text-gray-600 font-normal text-sm md:text-base font-display">Highly rated window tinting experts</p>
              </div>
              <ul className="pb-10 pt-6 px-8 space-y-6 text-gray-600">
 
      <li className="flex items-start">
                  <BadgeCheck size={24} className="text-brand-800 shrink-0" />
                  <div className="ml-4">
                    <span className="font-semibold font-display text-gray-800 block">Lifetime Warranty & Workmanship</span>
                    <p>Our work is guaranteed for life</p>
                  </div>
                </li>
              <li className="flex items-start">
                  <Check size={24} className="text-brand-800 shrink-0" />
                  <div className="ml-4">
                    <span className="font-semibold font-display text-gray-800 block">5-Star Rated on Yelp, Google, Facebook</span>
                    <p>Our customers love us!</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <ListOrdered size={24} className="text-brand-800 shrink-0" />
                  <div className="ml-4">
                    <span className="font-semibold font-display text-gray-800 block">20+ Years Of Experience in the Industry</span>
                    <p>Decades of unparalleled expertise</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <Sparkles size={24} className="text-brand-800 shrink-0" />
                  <div className="ml-4">
                    <span className="font-semibold font-display text-gray-800 block">We Only Use The Best Window Films</span>
                    <p>Top-quality products and materials</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <Users2 size={24} className="text-brand-800 shrink-0" />
                  <div className="ml-4">
                    <span className="font-semibold font-display text-gray-800 block">Certified Installers</span>
                    <p>Authorized, licensed, certified, and insured—so you'll never have to worry!</p>
                  </div>
                </li>

             






 
               
              </ul>
            </div>
            <div className="rounded-xl mb-2 shadow bg-white">
              <div class="px-8 py-6 flex items-start space-x-4">
               
                <div>
                  <div className="flex items-center">
               
                <div className="flex-1">
                  <h4 class="text-gray-900 font-bold text-lg md:text-xl font-display">Meet the Team</h4>
                  <h4 class="text-gray-600 font-medium text-base md:text-lg font-display">Certified Tint Professionals</h4></div>

                  <img
                  src="https://images.ctfassets.net/bjs1nof45l61/THY6mYjJzIbv5xhSf27AV/288e9a93c0b6cc59d5f4cec25412d55b/A__window_tinting_team.jpeg?w=500&h=334&fl=progressive&q=80&fm=jpg"
                  className="w-32 h-32 rounded-full border-2 border-white object-cover"
                />
                  </div>

                  <p className="text-gray-500 font-display font-normal text-sm">
                  At A+ Window Tinting, excellence is at the core of everything we do. Specializing in premium window tinting and windshield repair services, located in Roseville.  </p>
                  <Button className="bg-brand-600 mt-4" to="/contact/">
                    Get a Quote
                  </Button>
                </div>
              </div>
            </div>
          
            <div className="rounded-xl mb-2 shadow bg-white">
              <div class="px-8 py-6 flex items-start space-x-4">
                {/* <img
                  src="https://images.ctfassets.net/seqn6hyrh7p7/1YBBOd4h58mVjDFPWklph0/0b0634904698a95f1b53f337ce548401/Screen_Shot_2022-08-04_at_12.48.30_PM.png?h=250"
                  className="w-16 h-16 rounded-full border-2 border-white"
                /> */}
                <div>
                  <h4 class="text-gray-900 font-bold text-lg md:text-xl font-display">Talk to our experts</h4>
                  <p className="text-gray-500 font-display font-normal">
                    Free estimates, clear straightforward pricing
                  </p>
                  <Button className="bg-brand-600 mt-4" to="/contact/">
                    Get Pricing
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="rounded-xl mb-2 shadow bg-white mt-4 border-4 border-brand-100">
              <div class="px-8 py-6 flex items-center space-x-4">
                <img
                  src=" https://images.ctfassets.net/seqn6hyrh7p7/KhtI1D3NxmJaQZ1SdiXul/e041fdecc73b85da180569819df93db5/kindpng_4658234.png"
                  className="w-1/3"
                />
                <div>
                  <h3 class="text-brand-800 font-bold text-xl md:text-3xl font-display">$50 OFF</h3>
                  <p className="text-brand-800 font-display font-semibold text-lg ">Tesla Wall Charger Installation</p>
                
                  <Button className="mt-4" onClick={() => claimOffer()}>
                    {claimingInProgress ? (
                      <svg
                        class="animate-spin ml-3 mr-3 h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      "Claim"
                    )}
                  </Button>
                </div>
              </div>
            </div> */}
          </>
        );
      }}
    />
  );
};

export default WhyUs;
